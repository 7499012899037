import {
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
  signal,
} from '@angular/core';
import { AppService } from './app.service';
import { isPlatformServer } from '@angular/common';
import { SettingsModel } from '../models/setting.model';
import { TranslationModel } from '../models/translation.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  translationMap = new Map<number, string>();

  public settings = signal<SettingsModel>(new SettingsModel());

  private serverMobile = false;
  private serverTablet = false;

  constructor(
    private appService: AppService,
    @Optional() @Inject('mobile') protected mobile: boolean,
    @Optional() @Inject('tablet') protected tablet: boolean,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    this.serverMobile = mobile;
    this.serverTablet = tablet;
  }

  async getSettings(): Promise<any> {
    return this.appService.getData('settings');
  }

  getMobileStatus() {
    if (isPlatformServer(this.platformId)) {
      return this.serverMobile;
    }

    const ua = navigator.userAgent;
    if (
      /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua,
      )
    ) {
      return true;
    }
    return false;
  }

  setSettings(input: SettingsModel) {
    this.settings.set(input);
    this.setTranslationList();
  }

  getTabletStatus() {
    if (isPlatformServer(this.platformId)) {
      return this.serverTablet;
    }
    const ua = navigator.userAgent;

    if (ua.match(/Tablet|iPad|iPod/i)) {
      return true;
    }

    return false;
  }

  getImageType(url: string) {
    const isImage = url.match(/.(jpg|jpeg|png|webp|gif)$/i);
    if (isImage) {
      return true;
    }

    return false;
  }

  getVideoType(url: string) {
    url = url.toLowerCase();
    if (url.includes('.mp4')) {
      return 'video/mp4';
    }

    if (url.includes('.mov')) {
      return 'video/mov';
    }

    if (url.includes('.wmv')) {
      return 'video/wmv';
    }

    if (url.includes('.flv')) {
      return 'video/flv';
    }

    if (url.includes('.avi')) {
      return 'video/avi';
    }

    if (url.includes('.avchd')) {
      return 'video/avchd';
    }

    if (url.includes('.webm')) {
      return 'video/webm';
    }

    return 'video/mkv';
  }

  setTranslationList() {
    let translations: TranslationModel[] = [];
    translations = this.settings().Translations;

    translations.forEach((el) => {
      this.translationMap.set(el.TranslationId, el.TranslationString);
    });
  }

  getTranslation(translationId: number) {
    const output = this.translationMap.get(translationId);
    return output;
  }
}
