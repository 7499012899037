import { LandingPageListModel } from './landing-page-list.model';
import { LinkModel } from './link.model';
import { ListModel } from './list.model';
import { LocationListModel } from './location-list.model';
import { MediaModel } from './media.model';
import { PoiListModel } from './poi-list.model';
import { SearchFilterModel } from './search-filter.model';
import { SEOModel } from './seo.model';
import { TranslationModel } from './translation.model';

export class SearchBox {
  id?: number;
  Destination?: string;
  Date?: string;
  Length?: string;
  NumberOfPeople?: string;
  SearchButtonText?: string;
  Day?: string;
  Days?: string;
  Week?: string;
  Weeks?: string;
  Area?: string;
  BestMatches?: string;
  CalendarHelpText?: string;
  CalendarTooltip?: string;
  City?: string;
  Country?: string;
  DateBubbleHeader?: string;
  DateBubbleText?: string;
  DestinationBuddleHeader?: string;
  DestinationBubbleText?: string;
  Other?: string;
  OtherLocation?: string;
  Region?: string;
  Village?: string;
}

export class FooterModel {
  Contact?: string;
  ContactContent?: string;
  Countries?: string;
  CustomerService?: string;
  SocialMedia?: string;
  SocialMediaLinks: LinkModel[] = [];
  SocialMediaShareLinks: LinkModel[] = [];
  SubFooterLinks: LinkModel[] = [];
  Locations: LinkModel[] = [];
}

export class SettingsModel {
  ClientId: number = 0;
  WlUrl: string = '';
  City?: string;
  LocationTitleFallback?: string;
  LocationMetaDescriptionFallback?: string;
  PrimaryColor?: string;
  SecondaryColor?: string;
  HeaderBgColor?: string;
  HeaderColor?: string;
  FooterColor?: string;
  ButtonColor?: string;
  ButtonBgColor?: string;
  Translations: TranslationModel[] = [];
  MapMarker: MediaModel = new MediaModel();
  MapMarkerPoi: MediaModel = new MediaModel();
  Media: MediaModel = new MediaModel();
  LandingPages: LandingPageListModel[] = [];
  SearchFilters: SearchFilterModel[] = [];
  Facilities: SearchFilterModel[] = [];
  Logo?: string;
  Colors?: ColorsModel;
  GoogleAnalyticsTrackingId?: string;
  GoogleTagManagerId?: string;
  GoogleAdId?: string;
  GoogleAdConversionId?: string;
  CookieConsentText?: string;
  CookieExpirationText?: string;
}

export class ColorsModel {
  PrimaryColor?: string;
  SecondaryColor?: string;
  HeaderBgColor?: string;
  HeaderColor?: string;
  FooterBgColor?: string;
  FooterColor?: string;
  ButtonColor?: string;
  ButtonBgColor?: string;
  HeaderTransparentBgColor?: string;
  HeaderTransparentColor?: string;
  FontColor?: string;
  LinkColor?: string;
  CookieBgColor?: string;
  CookieColor?: string;
  SectionBgColor?: string;
  SectionColor?: string;
}
