export class MediaModel {
  Id?: number;
  MediaType?: number;
  Path?: string | null;
  Url: string = '';
  PageType?: number;
  PageId?: number;
  TypeId?: number;
  SortOrder?: number;
  ImageRatio?: number;
  Caption?: string | null;
}
