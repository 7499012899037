import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { firstValueFrom } from 'rxjs';

interface RequestOptions {
  headers?: Record<string, string>;
  params?: Record<string, string | number>;
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private defaultHeaders: Record<string, string> = {
    'Content-Type': 'application/json; charset=utf-8',
  };

  constructor(
    private httpClient: HttpClient,
    @Optional() @Inject(DOCUMENT) private document: Document | null,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  private isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  getData(url: string, options?: RequestOptions): Promise<any> {
    const getUrl = new URL(environment.apiDomain + url);

    const headers = new HttpHeaders({
      ...this.defaultHeaders,
      domainid: this.getClientId(),
      Origin: this.getOrigin(),
      ...options?.headers,
    });

    const params = this.buildParams(options?.params);

    // Allow self-signed certificates only during SSR
    if (this.isServer()) {
      process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = '0';
    }

    return firstValueFrom(
      this.httpClient.get<any>(getUrl.href, {
        headers,
        params,
        withCredentials: options?.withCredentials || false,
      }),
    )
      .then(this.extractData)
      .catch(this.handleError);
  }

  postData(url: string, body: any, options?: RequestOptions): Promise<any> {
    const postUrl = environment.apiDomain + url;

    const headers = new HttpHeaders({
      ...this.defaultHeaders,
      Origin: this.getOrigin(),
      ...options?.headers,
    });

    return firstValueFrom(
      this.httpClient.post<any>(postUrl, body, {
        headers,
        withCredentials: options?.withCredentials || false,
      }),
    )
      .then(this.extractData)
      .catch(this.handleError);
  }

  private extractData(res: Response) {
    const body = JSON.stringify(res);
    return body || {};
  }

  private getOrigin(): string {
    return this.document?.location.origin ?? environment.apiDomain;
  }

  private handleError(error: any): Promise<any> {
    console.error('API Error:', error);
    return Promise.reject(error?.message || error);
  }

  getClientId(): string {
    const url = this.document?.location.hostname ?? 'localhost';
    switch (url) {
      case 'localhost':
        return '1';
      case 'test.hotellstockholm.se':
      case 'hotellstockholm.se':
        return '1';
      case 'test.hotell-oslo.no':
      case 'hotell-oslo.no':
        return '2';
      default:
        return '1';
    }
  }

  private buildParams(params?: Record<string, string | number>): HttpParams {
    let httpParams = new HttpParams();
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        httpParams = httpParams.set(key, value.toString());
      });
    }
    return httpParams;
  }
}
