export const environment = {
  production: true,
  // apiDomain: 'https://localhost:7079/',
  apiDomain: 'https://ravenapi.hotell.no/',
  staticContent: 'E:/Hotel.Destination',
  imgFxDomain: 'https://hotell.imgfx.net/{mode}/size-{size}/',
  googleAnlayticsTrackingCode: 'UA-100770650-1',
  imgFxActive: true,
  cookieVersion: 1,
  googleMapScriptTag:
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyBmSNgsI3aq6xM3tnxAq5wJzQidt15Muns&libraries=marker&callback=googleMapsLoaded&map_id=e85b346110bedded&loading=async',
  sentryDsn:
    'https://9dcaf8bfad613971146682ac92982820@o4508726317547520.ingest.de.sentry.io/4508726660038736',
  initializeSentry: false,
};
